.cls-1 {
    stroke: #1d71b8;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    stroke-miterlimit: 10;
  }

  .cls-1, .cls-2, .cls-4 {
    fill: none;
    stroke-width: 8px;
  }

  .cls-2 {
    stroke: #008d36;
  }

  .cls-5 {
    fill: #010101;
  }

  .cls-5, .cls-6, .cls-7 {
    stroke-width: 0px;
  }

  .cls-6 {
    fill: #1d1d1b;
  }

  .cls-7, .cls-3 {
    fill: #ffffff;
  }

  .cls-3 {
    stroke: #111110;
    stroke-width: 2px;
  }

  .cls-4 {
    stroke: #f9b233;
  }



  g circle{
    transition: r 0.2s;
  }

 g g{
    transition: opacity 0.2s;
  }

  g:hover circle.k{
    r: 17;
  }

  g:hover circle.g{
    r: 25;
  }

 g:hover g{
    opacity: 0.5;
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .red-bg{
    background-color: #B91023;
  }

  .gradient {
    width: 100%;
    height: 100px;
  
    svg {
      height: 100%;
      width: 100%;
      .main {
        fill: #B91023;
      }
    }
  }